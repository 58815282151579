import mobile from "../../../assets/img/mobile.png";
import nout from "../../../assets/img/nout.png";
import pcomp from "../../../assets/img/pc.png";
import lamp from "../../../assets/img/lamp.png";
import loudspeaker from "../../../assets/img/loudspeaker.png";

export default () => {
    return {
        ServicesPageInfo: [
            {
                title: "Разработка мобильных приложений",
                item: mobile,
                description: "За 9 лет мы собрали внушительный опыт и экспертизу в электронной коммерции, мобильной и веб-разработке, digital маркетинге. Но самое важное —опыт совместного развития бизнеса с нашими клиентами. Благодаря этим знаниям истратегическому видению мы создаём бизнес-решения — продукты, которые органичноинтегрируются в ваш бизнес и становятся его точкой развития.",
                directions: [
                    {
                        direction: "Разработка мобильных приложений на Android"
                    },
                    {
                        direction: "Разработка мобильных приложений на IOS"
                    },
                    {
                        direction: "РАЗРАБОТКА МОБИЛЬНЫХ ПРИЛОЖЕНИЙ ПОД IOS И ANDROID"
                    }
                    ,
                    {
                        direction: "РАЗРАБОТКА МОБИЛЬНЫХ ПРИЛОЖЕНИЙ И ИНТЕГРАЦИЯ С 1С ПРЕДПРИЯТИЕ"
                    },

                ],
            },
            {
                title: "РАЗРАБОТКА ИНТЕРНЕТ-МАГАЗИНА",
                item: nout,
                description: "9 лет в электронной коммерции.\n" +
                    "\n" + " Мы создаём бизнес-решения с помощью инструментов консалтинга, мобильной и\n" +
                    "   web-разработки, digital-маркетинга.\n" +
                    "\n" + "  Определяем цели проекта, подбираем инструментарий и собираем продукт с мощным движком.\n" +
                    " Растём с клиентами и совершенствуем продукты вместе",
                directions: [
                    {
                        direction: "ИНТЕРНЕТ-МАГАЗИНЫ НА MAGENTO 2.3"
                    },
                    {
                        direction: "ПЕРЕХОД НА MAGENTO 2.3"
                    },
                    {
                        direction: "МАРКЕТПЛЕЙСЫ"
                    },
                    {
                        direction: "B2B РЕШЕНИЯ"
                    },
                    {
                        direction: "МОБИЛЬНЫЕ ПРИЛОЖЕНИЯ"
                    },
                    {
                        direction: "DIGITAL MARKETING"
                    },
                    {
                        direction: "ТЕХНИЧЕСКАЯ ПОДДЕРЖКА"
                    },

                ],
            },
            {
                title: "РАЗРАБОТКА ВЕБ САЙТОВ",
                item: pcomp,
                description: "Бизнес знает свои потребности, а мы — как их реализовать.Строим устойчивые корпоративные сайты и резвые промо-лендинги. Разрабатываем webсервисы. Наши продукты выдерживают огромную нагрузку и надёжно служат клиентам.",
                directions: [
                    {
                        direction: "СОЗДАНИЕ КОРПОРАТИВНОГО САЙТА"
                    },
                    {
                        direction: "ПРОМО-САЙТЫ"
                    },
                    {
                        direction: "WEB СЕРВИСЫ"
                    },
                    {
                        direction: "ТЕХНИЧЕСКАЯ ПОДДЕРЖКА"
                    },

                ],
            },
            {
                title: "СОЗДАНИЕ СТАРТАПА",
                item: lamp,
                description: "Воплощаем птицу идеи в действующий бизнес. Анализируем\n" +
                    "  концепции стартапов. Подвергаем сомнению и находим свет — выбираем то, во что искренне\n" +
                    "  верим. Консультируем и прокачиваем бизнес-модель продукта. Задаём маршрут, учим летать и\n" +
                    "  выпускаем в небо.",
                directions: [
                    {
                        direction: "БИЗНЕС КОНЦЕПТ"
                    },
                    {
                        direction: "ДЕМОВЕРСИЯ"
                    },
                    {
                        direction: "MVP"
                    },
                    {
                        direction: "ПОЛНОФУНКЦИОНАЛЬНЫЙ"
                    },
                ],
            },
            {
                title: "DIGITAL МАРКЕТИНГ",
                item: loudspeaker,
                description: "омогаем растущему бизнесу расти быстрее, а взрослому —\n" +
                    " достойно принимать вызовы времени.\n" +
                    "\n" + "Проводим тщательный анализ и создаём коммуникационную стратегию. Детально прорабатываем\n" +
                    " воронку. Оптимизируем каналы продвижения и запускаем рекламные кампании. Шлифуем имидж\n" +
                    " бренда через осмысленный контент.",
                directions: [
                    {
                        direction: "DIGITAL РЕКЛАМА"
                    },
                    {
                        direction: "АУДИТ И ОПТИМИЗАЦИЯ DIGITAL КАНАЛОВ"
                    },
                    {
                        direction: "WEB АНАЛИТИКА"
                    },
                    {
                        direction: "СОЗДАНИЕ РЕКЛАМНОГО КОНТЕНТА"
                    },
                    {
                        direction: "РАЗРАБОТКА СТРАТЕГИИ МЕДИАКОММУНИКАЦИЙ",
                    },

                ],
            },

        ],

        StrategyInfo: [
            {
                title:"Смотрим проницательно",
                description:"За 9 лет мы собрали внушительный опыт и экспертизу в электронной коммерции, мобильной и веб-разработке, digital маркетинге. Но самое важное — опыт совместного развития бизнеса с нашими клиентами. Благодаря этим знаниям и стратегическому видению мы создаём бизнес-решения — продукты, которые органично интегрируются в ваш бизнес и становятся его точкой развития."
            },
            {
                title:"Мыслим стратегически",
                description:"Мы знаем, как эффективно применять инструменты Digital. Поднимаемся выше вершин и анализируем горизонты развития каждого проекта. Опыт работы с лидерами рынка позволяет нам тестировать гипотезы на большом количестве пользователей и повышать эффективность бизнеса."
            },
            {
                title:"Делимся опытом",
                description:"От общего к деталям, от малого к большому. Изучаем каждую концепцию, разбираем до молекул, улучшаем и собираем крутой проект. Стремимся принести пользу с первой встречи и передать наш опыт каждому проекту, в который искренне верим."
            }
        ]

    }
}



