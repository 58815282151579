import OurProjects from "./OurProjects/OurProjects"
import FeedBackForm from "../../components/FeedBackForm/FeedBackForm"

const PortfolioPage = () => {
  return (
    <>
      <OurProjects />
      <FeedBackForm type={"black"} />
    </>
  );
}

export default PortfolioPage;
